<template>
  <core-section id="who-i-am">
    <abstract-1 />

    <core-heading>Who I am.</core-heading>

    <v-col
      cols="12"
      md="6"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <core-text class="mb-0">
            <p
              class="summary-text"
              v-html="schema.basics.summary"
            />
            <div>
              <v-img
                :src="require('@/assets/signature.png')"
                class="ml-auto"
                width="150"
              />
            </div>
          </core-text>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      class="hidden-sm-and-down text-right text-uppercase"
      cols="6"
    >
      <div
        class="text-h4"
        v-text="schema.basics.label"
      />

      <span
        class="primary--text text-h5 font-weight-light"
        v-text="schema.basics.name"
      />

      <v-img
        :src="require(`@/assets/salute.jpg`)"
        height="400"
        width="600"
        contain
        class="mx-auto mt-12"
      />
    </v-col>
  </core-section>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'WhoIAm',

    components: {
      Abstract1: () => import('@/components/Abstract1'),
    },

    computed: {
      ...mapState('app', ['schema']),
    },
  }
</script>

<style lang="sass">
  .v-expansion-panels > li
    border-top: 1px solid rgba(#fff, .12) !important

  .summary-text
    font-size: 1.16rem !important
</style>
